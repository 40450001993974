// Navigation Bar SECTION
const navBar = { show: true };

// Main Body SECTION
const mainBody = {

  // gradientColors: "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",
  gradientColors: '#333333, #333333',
  firstName: 'Bryan',
  middleName: '',
  lastName: 'Kizer',
  message: ' Senior Software Engineer ',
  icons: [
    {
      image: 'fa-github',
      url: 'https://github.com/belsrc',
    },

    // {
    //   image: "fa-facebook",
    //   url: "https://www.facebook.com/<user>",
    // },
    {
      image: 'fa-instagram',
      url: 'https://www.instagram.com/bryan_kizer/',
    },
    {
      image: 'fa-linkedin',
      url: 'https://www.linkedin.com/in/bryan-kizer-b2a1603a/',
    },

    // {
    //   image: "fa-twitter",
    //   url: "https://www.twitter.com/<user>/",
    // },
  ],
};

// ABOUT SECTION
// If you want the About Section to show a profile picture you can fill the profilePictureLink either with:
// a) your Instagram username
//      i.e:profilePictureLink:"johnDoe123",
// b) a link to an hosted image
//      i.e:profilePictureLink:"www.picturesonline.com/johnDoeFancyAvatar.jpg",
// c) image in "editable-stuff" directory and use require("") to import here,
//      i.e: profilePictureLink: require("../editable-stuff/johnDoeFancyAvatar.jpg"),
// d) If you do not want any picture to be displayed, just leave it empty :)
//      i.e: profilePictureLink: "",
// For Resume either provide link to your resume or import from "editable-stuff" directory
//     i.e resume: require("../editable-stuff/resume.pdf"),
//         resume: "https://docs.google.com/document/d/13_PWdhThMr6roxb-UFiJj4YAFOj8e_bv3Vx9UHQdyBQ/edit?usp=sharing",

const about = {
  show: true,
  heading: 'About Me',
  imageLink: '',
  imageSize: 375,
  message:
    'My name is Bryan Kizer. I’m a senior software engineer from Indiana. ' +
    "I'm passionate about coding and primarily use Javacript but have dabbled in various different languages. " +
    'Recently I have been focusing on functional-style programming and Typescript. ' +
    'In my free time I like to ride mototrcycles, hike, workout and play video games.',
  resume: require('../editable-stuff/resume.pdf'),
};

// PROJECTS SECTION
// Setting up project lenght will automatically fetch your that number of recently updated projects, or you can set this field 0 to show none.
//      i.e: reposLength: 0,
// If you want to display specfic projects, add the repository names,
//      i.e ["repository-1", "repo-2"]
const repos = {
  show: true,
  heading: 'Recent Projects',
  gitHubUsername: 'belsrc',
  reposLength: 0,
  specificRepos: [
    'fjp',
    'vue-gen',
    'eslint-config-belsrc',
    'vue-ts-demo',
    'react-ts-demo',
    'vue-test-functional-wrapper',
  ],
};

// LEADERSHIP SECTION
const leadership = {
  show: false,
  heading: 'Leadership',
  message:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et ' +
    'dolore magna aliqua. Vitae auctor eu augue ut lectus arcu bibendum at varius. Libero justo laoreet sit ' +
    'amet cursus sit amet. Imperdiet dui accumsan sit amet nulla facilisi morbi. At auctor urna nunc id. ' +
    'Iaculis urna id volutpat lacus laoreet non curabitur gravida.',

  // {
  //   img: require("../editable-stuff/image.png"),
  //   label: "Second slide label",
  //   paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum."
  // },
  images: [],
  imageSize: {
    width: '615',
    height: '450',
  },
};

// SKILLS SECTION
const skills = {
  show: true,
  heading: 'Skills',
  hardSkills: [
    { name: 'Javascript/Node', value: 95 },
    { name: 'Vue', value: 90 },
    { name: 'PHP', value: 80 },
    { name: 'SQL', value: 90 },
    { name: 'C/C++', value: 30 },
    { name: 'Rust', value: 10 },

    // --- End First Column ---
    { name: 'HTML/CSS', value: 95 },
    { name: 'React', value: 40 },
    { name: 'Python', value: 40 },
    { name: 'NoSQL', value: 90 },
    { name: 'C#', value: 50 },
    { name: 'Git', value: 90 },
  ],
  softSkills: [
    { name: 'Goal-Oriented', value: 80 },
    { name: 'Collaboration', value: 90 },
    { name: 'Positivity', value: 75 },
    { name: 'Adaptability', value: 85 },
    { name: 'Problem Solving', value: 75 },
    { name: 'Organization', value: 70 },
  ],
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: false,
  heading: 'Get In Touch',
  message:
    "I'm currently looking for full-time Software Engineering or Machine Learning opportunities! " +
    'If you know of any positions available, if you have any questions, or if you just want to say hi, ' +
    'please feel free to email me at',
  email: '<email>',
};

// EXPERIENCE SECTION
const experiences = {
  show: true,
  heading: 'Experience',
  data: [
    {
      role: 'Senior Front-End Engineer',
      companylogo: require('../assets/img/vl-logo.png'),
      date: 'August 2017 – Present',
    },
    {
      role: 'Full Stack Developer',
      companylogo: require('../assets/img/carved.webp'),
      date: 'March 2016 – August 2017',
    },
    {
      role: 'Full Stack Developer',
      companylogo: require('../assets/img/strata.png'),
      date: 'November 2012 – March 2016',
    },
  ],
};

export {
  navBar, mainBody, about, repos, skills, leadership, getInTouch, experiences,
};
