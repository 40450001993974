import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import {
  navBar,
  mainBody,
  about,
  repos,
  leadership,
  skills,
  getInTouch,
  experiences,
} from './editable-stuff/config';
import MainBody from './components/home/MainBody';
import AboutMe from './components/home/AboutMe';
import Project from './components/home/Project';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Skills from './components/home/Skills';
import GetInTouch from './components/home/GetInTouch';
import Leadership from './components/home/Leadership';
import Experience from './components/home/Experience';

const Home = React.forwardRef((props, ref) =>
  <>
    <MainBody
      gradient={ mainBody.gradientColors }
      title={ `${ mainBody.firstName } ${ mainBody.middleName } ${ mainBody.lastName }` }
      message={ mainBody.message }
      icons={ mainBody.icons }
      ref={ ref } />
    { about.show &&
      <AboutMe
        heading={ about.heading }
        message={ about.message }
        link={ about.imageLink }
        imgSize={ about.imageSize } />
    }
    { repos.show &&
      <Project
        heading={ repos.heading }
        username={ repos.gitHubUsername }
        length={ repos.reposLength }
        specfic={ repos.specificRepos } />
    }
    { leadership.show &&
      <Leadership
        heading={ leadership.heading }
        message={ leadership.message }
        img={ leadership.images }
        imageSize={ leadership.imageSize } />
    }
    { skills.show &&
      <Skills
        heading={ skills.heading }
        hardSkills={ skills.hardSkills }
        softSkills={ skills.softSkills } />
    }
    { experiences.show && <Experience experiences={ experiences } /> }
  </>);

const App = () => {
  const titleRef = React.useRef();

  return (
    <BrowserRouter basename={ process.env.PUBLIC_URL + '/' }>
      { navBar.show && <Navbar ref={ titleRef } /> }
      <Route path='/' exact={ true } component={ () => <Home ref={ titleRef } /> } />
      <Footer>
        { getInTouch.show &&
          <GetInTouch
            heading={ getInTouch.heading }
            message={ getInTouch.message }
            email={ getInTouch.email } />
        }
      </Footer>
    </BrowserRouter>
  );
};

export default App;
