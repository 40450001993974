import React from 'react';
import Container from 'react-bootstrap/Container';

const Footer = props => {
  const bgStyle = { backgroundColor: '#f5f5f5' };

  return (
    <footer style={ bgStyle } className='mt-auto py-5 text-center '>
      <Container>
        { props.children }
        <i className='fas fa-code' /> with <i className='fas fa-heart' /> by{ ' ' }
        <a
          className='badge badge-dark'
          rel='noopener noreferrer'
          target='_blank'
          href='https://github.com/belsrc'
          aria-label='My GitHub'>
          Bryan Kizer
        </a>
      </Container>
    </footer>
  );
};

export default Footer;
