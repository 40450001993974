import React from 'react';
import Typist from 'react-typist';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';

const MainBody = React.forwardRef(({ gradient, title, message, icons }, ref) =>
  <Jumbotron
    fluid={ true }
    id='home'
    style={{
      background: `linear-gradient(136deg,${ gradient })`,
      backgroundSize: '1200% 1200%',
      position: 'relative',
    }}
    className='title bg-transparent bgstyle text-light min-vh-100 d-flex align-content-center align-items-center flex-wrap m-0'>
    <div id='stars'></div>
    <Container className='text-center'>
      <h1 ref={ ref } className='display-1'>
        { title }
      </h1>
      <Typist className='lead typist' cursor={{ show: false }}>
        { ' ' }
        { message }
      </Typist>
      <div className='p-5'>
        { icons.map((icon, index) =>
          <a
            key={ `social-icon-${ index }` }
            target='_blank'
            rel='noopener noreferrer'
            href={ icon.url }
            aria-label={ `My ${ icon.image.split('-')[1] }` }>
            <i className={ `fab ${ icon.image }  fa-3x socialicons` } />
          </a>) }
      </div>
    </Container>
    <div class='scroll-animation'>
      <div class='chevron'></div>
      <div class='chevron'></div>
      <div class='chevron'></div>
    </div>
  </Jumbotron>);

export default MainBody;
