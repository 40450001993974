import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Row from 'react-bootstrap/Row';
import { Jumbotron, Container } from 'react-bootstrap';
import { useScrollPosition } from '../../hooks/useScrollPosition';
import SkillsTab from './SkillsTab';

function Skills({ heading, hardSkills, softSkills }) {
  const skillsTabRef = React.useRef();
  const [ isScrolled, setIsScrolled ] = React.useState(false);

  // const navbarDimensions = useResizeObserver(navbarMenuRef);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if(!isScrolled && currPos.y - 400 < 0) {
        setIsScrolled(true);
      }
    },
    [],
    skillsTabRef,
  );

  return (
    <Jumbotron ref={ skillsTabRef } fluid={ true } className='bg-white m-0' id='skills'>
      <Container className='p-5 '>
        <h2 ref={ skillsTabRef } className='display-4 pb-5 text-center'>
          { heading }
        </h2>
        <Tabs className='skills-tabs' defaultActiveKey='hard-skills' id='skills-tabs'>
          <Tab tabClassName='skills-tab lead' eventKey='hard-skills' title='Technical Skills'>
            <Row className='pt-3 px-1'>
              <SkillsTab skills={ hardSkills } isScrolled={ isScrolled } />
            </Row>
          </Tab>
          <Tab tabClassName='skills-tab lead' eventKey='soft-skills' title='Soft Skills'>
            <Row className='pt-3 px-1'>
              <SkillsTab skills={ softSkills } isScrolled={ isScrolled } />
            </Row>
          </Tab>
        </Tabs>
      </Container>
    </Jumbotron>
  );
}

export default Skills;
