import React from 'react';
import { Container, Row, Jumbotron } from 'react-bootstrap';
import ExperienceCard from './ExperienceCard';

const Experience = ({ experiences }) =>
  <section id='experience' className='section'>
    <Container>
      <Jumbotron fluid={ true } className='bg-white'>
        <h2 className='display-4 mb-5 text-center'>{ experiences.heading }</h2>
        <Row>
          { experiences.data.map(data =>
            <ExperienceCard key={ data.company } data={ data } />) }
        </Row>
      </Jumbotron>
    </Container>
  </section>

;

export default Experience;
