import React from 'react';
import { Col, Card } from 'react-bootstrap';

const ExperienceCard = ({ data }) =>
  <Col lg='6'>
    <div className='pb-5 text-center experience-card'>
      <div class='image-wrapper'>
        <img className=' bg-white mb-3 company-logo' src={ data.companylogo } alt='' />
      </div>
      <p className='lead'>
        { data.role }
        <br />
        { data.date }
      </p>
    </div>
  </Col>

;

export default ExperienceCard;
