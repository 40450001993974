import React, { useState, useEffect, useCallback } from 'react';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import ProjectCard from './ProjectCard';

const dummyProject = {
  name: null,
  description: null,
  svn_url: null,
  stargazers_count: null,
  languages_url: null,
  pushed_at: null,
};
const API = 'https://api.github.com';

const Project = ({ heading, username, length, specfic }) => {
  const allReposAPI = `${ API }/users/${ username }/repos?sort=updated&direction=desc`;
  const specficReposAPI = `${ API }/repos/${ username }`;
  const dummyProjectsArr = new Array(length + specfic.length).fill(dummyProject);

  const [ projectsArray, setProjectsArray ] = useState([]);

  const fetchRepos = useCallback(async () => {
    let repoList = [];

    try {
      // getting all repos
      const response = await axios.get(allReposAPI);

      repoList = [...response.data.slice(0, length)];

      // adding specified repos
      try {
        for(const repoName of specfic) {
          const res = await axios.get(`${ specficReposAPI }/${ repoName }`);

          repoList.push(res.data);
        }
      }
      catch(error) {
        console.error(error.message);
      }

      // setting projectArray
      // TODO: remove the duplication.
      return setProjectsArray(repoList);
    }
    catch(error) {
      return console.error(error.message);
    }
  }, [
    allReposAPI,
    length,
    specfic,
    specficReposAPI,
  ]);

  useEffect(() => {
    fetchRepos();
  }, [fetchRepos]);

  return (
    <Jumbotron fluid={ true } id='projects' className='bg-light m-0'>
      <Container className=''>
        <h2 className='display-4 pb-5 text-center'>{ heading }</h2>
        <Row>
          { projectsArray.length ?
            projectsArray.map((project, index) =>
              <ProjectCard
                key={ `project-card-${ index }` }
                id={ `project-card-${ index }` }
                value={ project } />) :
            dummyProjectsArr.map((project, index) =>
              <ProjectCard key={ `dummy-${ index }` } id={ `dummy-${ index }` } value={ project } />) }
        </Row>
      </Container>
    </Jumbotron>
  );
};

export default Project;
